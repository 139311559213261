import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

import { FooterMenu } from "./Menu";

export const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <StyledFooterBlock className="footer">
      <Container>
        <Row>
          <Col md="6" className="footer__nav">
            <h2>Cómo funciona</h2>
            <FooterMenu type="left" />
          </Col>
          <Col md="6" className="footer__nav_right">
            <h2>GoPrestamo</h2>
            <FooterMenu type="right" />
          </Col>
        </Row>
        <div className="footer__copyright">
          <p className="footer__copyright-info">
            Gopréstamo es una marca comercial de IDF Capital, S.A.P.I. de C.V.,
            SOFOM, ENR con domicilio en Calle Avenida Extremadura 59, piso 4,
            Colonia Insurgentes Mixcoac, Delegación Benito Juárez, C.P. 03920,
            en la Ciudad de México, México.
          </p>
          <p className="footer__copyright-date">
            &copy;&nbsp;{year}&nbsp;GoPrestamo.com
          </p>
        </div>
      </Container>
    </StyledFooterBlock>
  );
};

const StyledFooterBlock = styled.footer`
  background: #f4f4f4;
  padding-bottom: 40px;
  padding-top: 30px;
  @media (min-width: 768px) {
    padding: 60px 0 50px;
    margin: 20px 0 0;
  }
  h2 {
    margin: 0;
    color: #4c4c4c;
    font-size: 16px;
    font-weight: 700;
    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
  a {
    color: hsla(0, 0%, 30%, 0.64);
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    transition: color 0.15s ease;
    &:hover,
    &:focus {
      color: hsla(0, 0%, 30%, 0.8);
    }
  }
  .footer__nav_right {
    margin: 50px 0 0;
    @media (min-width: 768px) {
      margin: 0;
      text-align: right;
    }
  }
  .footer__copyright {
    border-top: 1px solid #d3d2d2;
    color: hsla(0, 0%, 30%, 0.8);
    font-size: 14px;
    margin-top: 50px;
    padding-top: 30px;
    @media (min-width: 768px) {
      position: relative;
      padding: 40px 0 0;
      margin: 40px 0 0;
      font-size: 16px;
      text-align: center;
    }
    &::before {
      @media (min-width: 768px) {
        content: "";
        position: absolute;
        top: -1px;
        left: 50%;
        width: 270px;
        height: 1px;
        background: #f4f4f4;
        transform: translateX(-50%);
      }
    }
    * {
      margin: 0;
    }
  }
  .footer__copyright-info {
    display: none;
    color: hsl(0, 0%, 30%);
    font-size: 12px;
    @media (min-width: 768px) {
      display: block;
    }
  }
  .footer__copyright-date {
    @media (min-width: 768px) {
      margin: 40px 0 0;
    }
  }
`;
